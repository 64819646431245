$primary: map-get($foundation-palette, primary);
$secondary: map-get($foundation-palette, secondary);

// DESKTOP MENU

$menu-underline-from-center-bg: $primary;
$menu-underline-border-thickness: 0.125rem;

.hover-underline-menu {
  width: 100%;

  .menu {
    a {
      color: $primary;
      padding: 0.7rem 1.8rem;
      line-height: 6;
      font-size: 15px;
      font-family: 'Alegreya', serif;
    }
    
    img {max-height: 100px;}

    .underline-from-center {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: calc(100% - #{$menu-underline-border-thickness});
        border-bottom: $menu-underline-border-thickness solid $primary;
        left: 50%;
        right: 50%;
        transition: all 0.5s ease;
      }

      &:hover::after {
        left: 0;
        right: 0;
        transition: all 0.5s ease;
      }
    }
  }
}

// END MENU




// MOBILE MENU

$curtain-menu-color: $primary-color;
$curtain-menu-text-color: $white;
$curtain-menu-font-size: rem-calc(16);

#curtain {
	height: 70px;
	background: $curtain-menu-color;
	img{margin-top: 5px; margin-bottom: 5px; max-height: 60px;}
	h4{
	  margin-top: 15px;
	  a {color: $curtain-menu-text-color;}
	}
}

.curtain-menu-trigger{
  background: $curtain-menu-color;
  border-radius: 50%;
	cursor: pointer;
	position: absolute;
	padding: 3px 8px;
	font-size: 15px;
	top: 18px;
	right: 12px;
	z-index: 100;
	color: $white;
	transform: rotate(0);
	transition: all 600ms ease;
	&.open{
		color: $curtain-menu-text-color;
		transform: rotate(225deg);
	}
}

#nav-overlay{
	overflow: hidden;
	position: fixed;
	width: 100%;
	height: 100vh;
    z-index: 0;
	nav{
		width: 100%;
		overflow: hidden;
		position: absolute;
		top: -100%;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		list-style: none;
		z-index: 90;
		transition: top 1000ms ease;
		li{
			display: block;
			text-align: center;
			margin: 1rem 0;
		}
		a, a:visited{
			font-size: $curtain-menu-font-size;
			text-decoration: none;
			color: white;
			&:hover, &:focus, &:visited{
				color: $white;
			}
		}
	}
	&.open nav{
		top: -100px;
	}
	&:before,
	&:after{
		content: "";
		position: absolute;
		z-index: 50;
		top: 0;
		height: 100%;
		width: 100%;
		background: $curtain-menu-color;
		transition: transform 600ms, -webkit-transform 600ms, -o-transform 600ms;
	}

	&:before{
		left: 0;
		transform: skew(50deg) translateX(-200%);
		clip: rect(auto 50vw auto auto);
		@media(min-width: 500px){
			transform: skew(50deg) translateX(-150%);
		}
	}
	&.open:before{
		transform: skew(0) translateX(0%);
	}
	&:after{
		right: 0;
		transform: skew(50deg) translateX(200%);
		clip: rect(auto auto auto 50vw);
		@media(min-width: 500px){
			transform: skew(50deg) translateX(150%);
		}
	}
	&.open:after{
		transform: skew(0) translateX(0%);
	}
  &.open {z-index: 90;}
}

// END MOBILE MENU





// SOCIAL BAR

$social-button-size: 1.8rem;
$social-button-border-width: 0.125rem;
$social-button-font-size: 1rem;
$social-button-line-height: 1.6em;
$social-button-border-radius: 1.6875rem;
$social-button-transition: all 0.5s ease;
$social-button-margin: 0.25rem;

$social-brand-facebook: #3b5998;
$social-brand-twitter: #55acee;
$social-brand-whatsapp: #43d854;
$social-brand-linkedin: #007bb5;
$social-brand-mail: #555;
$social-brand-print: #777;

@mixin social-button($brand-color, $brand-icon) {
  background: $brand-color;

  &:before {
    font-family: "FontAwesome";
  }
}

.rounded-social-buttons {
  padding: 10px 0;

  @include breakpoint(small only) {
    text-align: center;
  }

  .social-button {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: $social-button-size;
    height: $social-button-size;
    border: $social-button-border-width solid transparent;
    padding: 0;
    text-decoration: none;
    text-align: center;
    color: $white;
    font-size: $social-button-font-size;
    font-weight: normal;
    line-height: $social-button-line-height;
    border-radius: $social-button-border-radius;
    transition: $social-button-transition;
    margin-right: $social-button-margin;
    margin-bottom: $social-button-margin;    

    &.facebook {
      background: $social-brand-facebook;
    }

    &.twitter {
      background: $social-brand-twitter;
    }

    &.linkedin {
      background: $social-brand-linkedin;
    }

    &.whatsapp {
      background: $social-brand-whatsapp;
    }

    &.mail {
      background: $social-brand-mail;
    }
    
    &:hover,
    &:focus {
      transform: rotate(360deg);
    }
  }
}

// END SOCIAL BAR





// HERO SECTION

$hero-height: 497px;

.hero-section {
  background: #000 url('../img/all_be_one.png') no-repeat center center;
//  background: url('../img/banner.jpg') 100% no-repeat;
  background-size: cover;
  height: $hero-height;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .hero-section-text {
    h2, h3, h5 {color: $white;}
    text-shadow: 1px 1px 2px $black;
  }
  
  @include breakpoint(small only) {
     background-size: cover;
     height: 25vh;
  }  
}

// END HERO SECTION