@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'building_blocks';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
@include foundation-grid;
@include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

// Colors
$primary: map-get($foundation-palette, primary);
$secondary: map-get($foundation-palette, secondary);
$off-white: #f8f8f8;

body {
    background: $off-white;
    overflow-x: hidden;
}

.grid-container, .row {max-width: 70rem;}
.pad {padding: 20px 0;}
.callout {border-radius: 10px; border-color: #e6e6e6;}
a {color: $secondary;}
a:hover {color: darken($secondary, 10%);}

.button {
	background: $primary;
	&:hover {
		background: $primary;
	}
	&:focus {
		background: $primary;
	}
}

.card {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    transform: translate3d(0px, 0px, 0px);
    transition: box-shadow 0.2s cubic-bezier(0, 0.2, 0.4, 1) 0s;
    border-radius: 5px;
}
.card-section, .card-divider {padding: 8px;}
.card-divider {background: $primary; h5 {color: $white;}}

a {
	color: $primary;
	&:hover {
		color: $primary;
	}
}

p {line-height: 1.4;}

#content {
    padding-top: 30px;
    min-height: 100vh;
    padding-bottom: 100px;
    h3, h5, h6 strong {color: #555;}
    p, li {color: #777;}    
}

#give {
	padding: 40px 0;
	background: $secondary;
	h4 {color: $white;}
}

#grid {
	a {color: #777;}
}

#content {height: 100%; min-height: 100vh;}

#subscribe {
    input[type="text"] {color: $primary; border-top-left-radius: 5px; border-bottom-left-radius: 5px;}
    input[type="submit"] {background: $primary; color: $white; border-top-right-radius: 5px; border-bottom-right-radius: 5px;}
}
blockquote {
    border: 0;
}

.scrollup {
  background: $primary;
  padding: 6px;
  border-radius: 5px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  z-index:99999;
}

footer {
  width: 100%;
  padding: 20px 0;
  background-color: $primary;

  h5 {color: white;}
  p, li {color: $white; margin-bottom: 0; font-size: 15px;}
  li {list-style: none;}
  ul {margin-left: 0;}
  a, a:hover {color: $white;}
	
	#sitemap {li {padding: 6px 0;}}
  #social i {font-size: 25px; margin-right: 8px;}
  .seperator {padding: 0 8px;}
}

/**** Preventing FOUC (flash of unstyled content) ****/
.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}
